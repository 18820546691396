/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Moment from "react-moment";
import Img from "gatsby-image";
import Markdown from "react-markdown";
import Bio from "../components/blog/Bio";
import Layout from "../components/blog/Layout";
import SEO from "../components/blog/seo";

import styles from "./BlogPost.module.css";

const BlogPostTemplate = ({ data, /* pageContext, */ location }) => {
  const post = data.strapiArticle;
  const siteTitle = "test";
  // data.site.siteMetadata.title;
  // const { previous, next } = pageContext;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={post.title} description={post.description || post.excerpt} />
      <article className={styles.BlogPost}>
        <header className={styles.BlogPostHeader}>
          <h1>{post.title}</h1>
          <div className={styles.BlogPostSubTitle}>
            <small className={styles.BlogPostDate}>
              <Moment format="MMM Do YYYY">{post.date}</Moment>
            </small>
            {post.category && (
              <>
                <div className={styles.BlogPostSubTitleSeparator}>/</div>
                <small className={styles.BlogPostCategory}>
                  {post.category}
                </small>
              </>
            )}
          </div>
        </header>
        <section>
          <Img
            fluid={post.cover.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </section>
        <section className={styles.BlogPostDescription}>
          {post.description}
        </section>
        <section className={styles.BlogPostBody}>
          <Markdown source={post.content} escapeHtml={false} />
        </section>
        <hr className={styles.BlogPostLine} />
        <footer>
          <Bio />
        </footer>
      </article>

      {/* <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav> */}
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  // pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticle(id: { eq: $id }) {
      cover {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      date
      slug
      category
      title
      content
      description
    }
  }
`;
